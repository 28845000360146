<template>
  <div>
    <asom-modal title="Create Manual Change Month End Declaration" v-model="showModal" :dismissible="false">
      <p>
        Confirm the creation of manual changes to the end of month declaration
      </p>
      <p class="pt-4">Are you sure you would like to proceed?</p>
      <div class="flex flex-row-reverse pt-4">
        <div class="pl-4">
          <asom-button @click="onSubmit" :disabled="isSubmitting" :loading="isSubmitting" text="OK" />
        </div>
        <div>
          <asom-button @click="onCloseModal" text="Cancel" variant="secondary" />
        </div>
      </div>
    </asom-modal>
    <asom-card>
      <form class="grid sm:grid-cols-1 md:grid-cols-3 gap-4">
        <asom-alert class="col-span-3" v-if="error" variant="error" :error-message="error" />

        <div class="col-span-3 flex flex-wrap">
          <div class="mt-5 font-medium mr-3">Report By* :</div>
          <div class="mt-3">
            <asom-input-text :disabled="true" type="text" v-model="userName" />
          </div>
        </div>

        <div class="col-span-3 grid sm:grid-cols-1 md:grid-cols-3 gap-4">
          <asom-form-field label="Line" required error="Please select a MRT line">
            <asom-input-select :disabled="true" v-model="filters.line" :options="lineOptions" />
          </asom-form-field>
          <asom-form-field label="Zone" required>
            <asom-input-select :disabled="true" v-model="filters.zoneId" :options="zoneList" />
          </asom-form-field>
          <asom-form-field label="Station" required error="Please select a MRT station">
            <asom-input-select :disabled="true" v-model="filters.station" :options="stationOptions" />
          </asom-form-field>
        </div>

        <div class="col-span-3 flex flex-wrap">
          <div class="mt-12 font-medium mr-3">Manual Change balance as at End-of-Traffic date (on)</div>
          <div class="mt-10 font-medium mr-3">
            <asom-input-text class="font-semibold" :disabled="true" type="text" v-model="filters.dateFrom" />
          </div>
          <div class="mt-12 font-medium ml-3 mr-1">,</div>
          <div class="mt-12 font-medium">recorded on the Station Manual Change Float Record.</div>
        </div>
        <div class="col-span-3 flex flex-wrap">
          <div class="mt-5 font-medium mr-3">MONTHLY MANUAL CHANGE COUNT IS FOR YEAR :</div>
          <div class="mt-3">
            <asom-input-select-year v-model="filters.year" />
          </div>
          <div class="mt-5 font-medium ml-3 mr-3">, and MONTH of</div>
          <div class="mt-3">
            <asom-input-select-month v-model="filters.month" :yearVal="filters.yearActive" />
          </div>
        </div>
        <div class="col-span-3 flex flex-wrap">
          <div class="mt-5 font-medium mr-3">Manual Change balance as at End-of-Traffic recorded on the 'Station
            Manual Change '. ($)</div>
          <div class="mt-3">
            <asom-input-text class="font-semibold" type="text" v-model="psmAmount" :state="inputStates('psmAmount')" />
          </div>
          <div class="mt-2">
            *All Manual Change bags with the status of closed, opened & used are to be declared, including those in the safe.
          </div>
        </div>
        <div class="col-span-3 flex flex-wrap">
          <div class="mt-5 font-medium mr-3">Remark:</div>
          <div class="mt-3 w-100">
            <asom-input-textarea style="width: 600px" placeholder="Remark" v-model="remark"/>
          </div>
        </div>
        <!-- 表格 -->
        <div v-if="teBags.length"  class="flex flex-col col-span-3">
          <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div class="shadow overflow-hidden border-gray-200 sm:rounded-lg">

                <div class="space-y-2 align-center">
                  <div v-for="(li, p) in teBags" :key="p"
                    class="px-6 py-4 grid sm:grid-cols-1 text-sm md:grid-cols-3 gap-4">
                    <div class="flex flex-row items-center space-y-2">
                      TAMPER EVIDENT BAG S/NO.
                      <asom-input-text :disabled="true" class="ml-3" type="text" v-model="li.bagNo" />
                    </div>
                    <div class="flex flex-row items-center space-y-2">
                      CASH DECLARATION FORM S/NO.
                      <asom-input-text :disabled="true" class="ml-3" type="text"
                        v-model="li.cashDeclarationFormRefrenceNo" />
                    </div>
                    <div class="flex flex-row items-center space-y-2">
                      AMOUNT ($)
                      <asom-input-text class="ml-3" type="text" v-model="li.amount" />
                    </div>
                  </div>
                </div>

                <div class="border-t bg-gray-50 flex justify-end space-x-4">
                  <div
                    class="px-6 py-3 text-left font-large text-black text-sm font-semibold flex flex-wrap items-center">
                    Total Amount Declared
                    <asom-input-text class="font-semibold ml-3" :disabled="true" type="text" v-model="totalNum" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="col-span-3 text-center mt-8">
          <div class="ml-4 text-sm text-gray-500">No data available.</div>
        </div>

        <div class="col-span-3 flex justify-end space-x-4">
          <asom-button text="Back" variant="secondary" @click="$router.go(-1)" />
          <asom-button text="Submit" @click="submitClicked" :loading="isSubmitting" />
        </div>
      </form>
    </asom-card>
  </div>
</template>

<script>
import get from 'lodash.get'
import {
  required,
  //  numeric, minLength, maxLength
} from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import { mapGetters } from "vuex";
import moment from "moment";
import {  createMonthendDeclarAtions,monthEndDeclarAtionSummary } from "@/services/cashManagement.service";
import { ATTACHMENT_CATEGORIES } from "@/constants/APIEnums/attachmentEnums";

import { parseDateTimeToUtc } from "@/helpers/dateTimeHelpers";
export default {
  name: "CashDeclarationFormCreate",
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      filters: {
        dateFrom: '',
        line: null,
        station: null,
        zoneId: null,
        yearActive: parseFloat(moment().format('YYYY')),
        year: {
          label: moment().format('YYYY'),
          value: moment().format('YYYY'),
        },
        month: '',
      },
      firstTime: parseDateTimeToUtc(moment().subtract(1,'months').startOf('month').format('YYYY-MM-DD 00:00:00')),
      lastTime: parseDateTimeToUtc(moment().subtract(1,'months').endOf("month").format('YYYY-MM-DD 23:59:59')),
      inputValidation: false,
      isLoading: false,
      isSubmitting: false,
      error: null,
      showModal: false,

      zoneList: [],
      psmAmount: null,
      teBags: [],
      remark: ''
    };
  },
  watch: {
    "filters.line": function (newValue) {
      if (newValue !== this.filters.line) {
        this.filters.station = null;
      }
    },
    "filters.year": function (val) {
      this.filters.dateFrom = '';
      this.filters.month = '';
      this.filters.yearActive = val.value;
    },
    "filters.month": function (newValue) {
      if (newValue) {
        const monthDate = moment(`${this.filters.yearActive}-${newValue.value}`);
        const time = monthDate.endOf("month").format('DD/MM/YYYY');
        this.filters.dateFrom = time;
        this.firstTime = parseDateTimeToUtc(monthDate.startOf("month").format('YYYY-MM-DD 00:00:00'));
        this.lastTime =  parseDateTimeToUtc(monthDate.endOf("month").format('YYYY-MM-DD 23:59:59'));
        this.$nextTick(() => {
          this.reporInit();
        })
      }
    },
  },
  validations() {
    let rules = {
      psmAmount: { required }
    }
    return rules;
  },
  computed: {
    ...mapGetters({
      // userId: "auth/userId",
      userName: "auth/userName",
      stationId: "selectedStation/id",
      lineOptions: "smrtResource/lineOptions",
      lineId: "selectedStation/lineId",
      // canSelectLine: 'auth/canSelectLine',  //是否管理员
    }),
    stationOptions() {
      return this.$store.getters["smrtResource/stationOptionsByLineId"](
        get(this.filters.line, "value")
      );
    },
    attachmentCategories() {
      return ATTACHMENT_CATEGORIES;
    },
    totalNum() {
      return this.teBags.reduce((p, item) => {
        return p + parseInt(item.amount) || 0;
      }, 0)
    }
  },
  mounted() {
    this.init();
    this.setDefaultStation();
  },
  methods: {
    init() {
      this.initZone();
    },
    async reporInit() {
      const params = {
        LineId: this.lineId,
        StationId: this.stationId,
        ZoneId: this.$store.getters['auth/userZoneId'],
        DateFrom: this.firstTime,
        DateTo: this.lastTime,
      }
      const res = await monthEndDeclarAtionSummary(params);
      if (res.success) {
        const payload = res.payload;
        this.psmAmount = payload.psmAmount;
        this.teBags = payload.data;
        this.psmTransactionIds = payload.psmTransactionIds;
      }
    },
    setDefaultStation() {
      // 默认为用户选择的站点线路
      const defaultSelectedLineId = this.$store.getters["selectedStation/lineId"];
      const defaultSelectedStationId = this.$store.getters["selectedStation/id"];
      // 获取该线路下的站点
      const stationOptions = this.$store.getters["smrtResource/stationOptionsByLineId"](defaultSelectedLineId);

      const defaultSelectedLine = this.lineOptions.find(line => line.value === defaultSelectedLineId);
      const defaultSelectedStation = stationOptions.find(station => station.value === defaultSelectedStationId);

      if (defaultSelectedLine && defaultSelectedStation) {
        this.filters.line = defaultSelectedLine;
        this.filters.station = defaultSelectedStation;
      }
    },
    async initZone() {
      this.filters.zoneId = {
        value: this.$store.getters["selectedStation/zoneId"],
        label: this.$store.getters["selectedStation/zoneName"]
      }
    },
    submitClicked() {
      if (this.isSubmitting) return;
      this.error = "";
      this.v$.$reset();
      this.v$.$touch();
      if (!this.validation()) return;
      this.showModal = true;
    },
    async onSubmit() {
      this.isSubmitting = true;
      const filters = this.filters;
      const params = {
        dateCreated: parseDateTimeToUtc(filters.dateFrom),
        lineId: filters.line.value,
        zoneId: filters.zoneId.value,
        stationId: filters.station.value,
        month: `${filters.year.value}-${filters.month.value}-01`,
        manualChangeBalance: parseFloat(this.psmAmount),
        totalAmountDeclared: this.totalNum,
        TeBags: this.teBags.map(p => {
              return {
                cashDeclarationFormId: p.cashDeclarationFormId,
                amount: parseFloat(p.amount),
                bagNo: p.bagNo
              }
        }),
        psmTransactionIds: this.psmTransactionIds,
        remark: this.remark
      }
      // console.log('提交-----',params);
      const res = await createMonthendDeclarAtions(params);
      this.isSubmitting = false;
      this.showModal = false;
      if (res.success) {
        this.$router.push({ name: "Month End Declaration Form" });
      } else {
        this.error = res.payload;
        this.$scrollTop();
      }
    },

    validation() {
      const amountLis = [];
      this.teBags.forEach((p) => {
        amountLis.push(parseFloat(p.amount));
      })
      const err = amountLis.filter(r => r != 0 && isNaN(r));
      if (!this.psmAmount) {
        this.error = "Please enter manual balance change";
        this.$scrollTop();
        return false;
      } else if (err.length) {
        this.error = "Please enter valid amount";
        this.$scrollTop();
        return false;
      } else {
        this.error = "";
        return true;
      }
    },
    onCloseModal() {
      this.showModal = false;
    }
  },
};
</script>
